import { Component } from "react";

import IUnreadMessagesCounterProps from "./interfaces/IUnreadMessagesCounterProps";

class UnreadMessagesCounter extends Component<IUnreadMessagesCounterProps> {
  private get unreadMessagesCount(): string {
    if (!this.props.unreadMessagesCount) {
      // Should not be possible
      return "";
    }

    const maxMessageCountToShow = 99;

    return this.props.unreadMessagesCount > maxMessageCountToShow
      ? `${maxMessageCountToShow}+`
      : this.props.unreadMessagesCount.toString();
  }

  public render(): JSX.Element {
    if (this.props.showMessageCounter && this.props.unreadMessagesCount != null) {
      return (
        <span className="ms-2 messages-unread">
          {this.unreadMessagesCount}
          <span className="visually-hidden">unread messages</span>
        </span>
      );
      // return <div className="unread-messages-counter">{this.unreadMessagesCount}</div>;
    }
    return <></>;
  }
}

export default UnreadMessagesCounter;

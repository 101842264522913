import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import CleaningObjectOpenNotifications from "./cleaningObjectOpenNotifications";
import { IFloorGroupState } from "./interfaces/IFloorGroupState";
import { IFloorGroupStateProps } from "./interfaces/IFloorGroupStateProps";
import ScheduleCleaningObject from "./scheduleCleaningObject";

class ScheduleCleaningObjectsFloorAccordion extends React.Component<IFloorGroupStateProps, IFloorGroupState> {
  public constructor(props: IFloorGroupStateProps) {
    super(props);

    this.state = {
      isAccordionOpen: false,
    };

    this.toggleAccordionStateOverview = this.toggleAccordionStateOverview.bind(this);
  }

  private toggleAccordionStateOverview(): void {
    this.setState({
      isAccordionOpen: !this.state.isAccordionOpen,
    });
  }

  private get shouldRenderCleaningObjects(): boolean {
    return this.state.isAccordionOpen || this.props.isSearchStringFilterApplied;
  }

  private get renderCleaningObjects(): JSX.Element {
    return (
      <>
        <div className="btn--content__accordion-items">
          {this.props.floor.cleaningObjects.map(cleaningObject => (
            <ScheduleCleaningObject key={cleaningObject.id} cleaningObject={cleaningObject} isAccordionItem={true} />
          ))}
        </div>
      </>
    );
  }

  public render(): JSX.Element {
    return (
      <>
        <button
          className={`btn btn--content${this.shouldRenderCleaningObjects ? " rounded-bottom-0" : ""}`}
          onClick={this.toggleAccordionStateOverview}
        >
          <div className="btn--content_block">
            <div>
              <div className="btn--content__content">
                <h2>{`${this.props.floor.name} (${this.props.floor.cleaningObjects.length})`}</h2>
              </div>
              <div className="btn--content__message">
                <CleaningObjectOpenNotifications
                  slaNotificationCount={
                    this.props.floor.cleaningObjects.filter(cleaningObjects => cleaningObjects.nextSlaDateTime != null)
                      .length
                  }
                  notifications={this.props.floor.cleaningObjects.flatMap(
                    cleaningObject => cleaningObject.notifications
                  )}
                />
              </div>
            </div>
            <div className="btn--content__end">
              {!this.shouldRenderCleaningObjects && this.props.floor.cleaningObjects.length !== 0 && (
                <FontAwesomeIcon icon={["fal", "chevron-down"]} fixedWidth className="align-middle" />
              )}
              {this.shouldRenderCleaningObjects && this.props.floor.cleaningObjects.length !== 0 && (
                <FontAwesomeIcon icon={["fal", "chevron-up"]} fixedWidth className="align-middle" />
              )}
            </div>
          </div>
        </button>
        {this.shouldRenderCleaningObjects && this.renderCleaningObjects}
      </>
    );
  }
}

export default ScheduleCleaningObjectsFloorAccordion;

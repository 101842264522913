import _ from "lodash";

import { ActionTypes, ActionTypesCollection } from "../actionTypes";
import { IScheduleState, initialScheduleState } from "../state/scheduleStoreState";

function scheduleReducer(state: IScheduleState = initialScheduleState, action: ActionTypesCollection): IScheduleState {
  switch (action.type) {
    case ActionTypes.FETCHED_SCHEDULE:
      return { ...state, schedule: action.payload };
    case ActionTypes.FETCHED_CLEANING_OBJECT:
      return { ...state, selectedCleaningObject: action.payload };
    case ActionTypes.FETCHED_LOCATION_OVERVIEW: {
      const locations = _.sortBy(action.payload, (l) => l.name.toLocaleUpperCase());
      return { ...state, locations };
    }
    case ActionTypes.SET_ACTIVE_LOCATION:
      return { ...state, selectedLocation: action.payload };
    case ActionTypes.SET_ACTIVE_ACTIVITIES_CHECKOUT_TRIGGER:
      return { ...state, currentCheckoutTrigger: action.payload };
    case ActionTypes.SET_SCANNED_FLOOR:
      return { ...state, scannedFloorId: action.payload };
    default:
      return state;
  }
}

export default scheduleReducer;

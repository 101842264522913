import CheckoutTriggerType from "enums/checkoutTriggerType";
import ICleaningObject from "interfaces/ICleaningObject";
import ILocationOverview from "interfaces/ILocationOverview";
import ISchedule from "interfaces/ISchedule";
import ISelectedLocation from "interfaces/ISelectedLocation";

export interface IScheduleState {
  schedule?: ISchedule;
  selectedCleaningObject?: ICleaningObject;
  locations?: ILocationOverview[];
  selectedLocation?: ISelectedLocation;
  currentCheckoutTrigger?: CheckoutTriggerType;
  scannedFloorId?: string;
}

export const initialScheduleState: IScheduleState = {};
